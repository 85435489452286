<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <b-row class="">

          <!-- Image & Replace image button  -->

          <b-col cols="2">

            <div class="d-md-flex flex-row mb-2  text-md-left text-center ">
              <b-avatar :src="getUserImage(currentUser.user.id, true)"
                        variant="light-primary"
                        class="mt-2 mr-3 mr-md-0"
                        size="70px"/>
              <!--                           <b-avatar v-else :src="form.photo_url"-->
              <!--                                     class="mt-2 mr-3 mr-md-0"-->
              <!--                                     size="70px"/>-->

            </div>
          </b-col>
          <b-col cols="10">
            <div v-if="changeImageButton" class=" mt-3 font-weight-bolder profile-text">
              <b-button class="" variant="primary" @click="changeImage()">
                <span class="align-middle">Change Image</span>
              </b-button>
              <b-button v-if="currentUser.user.photo_url" class="ml-1" variant="danger" @click="removeImage()">
                <span class="align-middle ">Remove Image</span>
              </b-button>

              <!--                              <b-row class="pl-0 ">-->
              <!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
              <!--                                    <span class="text-dark ">Maximum upload size 1MB</span>-->
              <!--                                  </b-col>-->

              <!--                              </b-row>-->
            </div>
            <div v-if="!changeImageButton" class="mt-2">
              <b-form-file
                  id="wildcard"
                  v-model="uploadImage"
                  accept="image/*"
              />
              <b-button class="float-right ml-1 mt-1" variant="" @click="changeImageButton = true">
                <span class="align-middle">Cancel</span>
              </b-button>
              <b-button :disabled="uploadImage.length===0" class="float-right mt-1" variant="primary"
                        @click="uploadFile()">
                <span class="align-middle">Upload</span>
              </b-button>

            </div>

          </b-col>


          <!-- first name -->

          <b-col class="" cols="12" xl="12">
            <validation-observer ref="EditPersonalInfoForm">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12" md="12">


                    <b-form-group
                        label="First Name"
                        label-for="h-first-name"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required|name_length"
                      >
                        <b-form-input id="h-first-name"
                                      v-model="form.first_name"
                                      class="mb-1"
                                      placeholder="First Name"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Last Name"
                        label-for="h-lst-name"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required|name_length"
                      >
                        <b-form-input id="h-last-name"
                                      v-model="form.last_name"
                                      class="mb-1"
                                      placeholder="Last Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--            <b-col-->
                  <!--                cols="12" md="12"-->
                  <!--            >-->
                  <!--              <b-form-group-->
                  <!--                  label="Role"-->
                  <!--                  label-for="readOnlyInput"-->
                  <!--              >-->
                  <!--                <b-form-input-->
                  <!--                    v-model="form.role"-->
                  <!--                    class="mb-1"-->
                  <!--                    readonly-->
                  <!--                />-->
                  <!--              </b-form-group>-->
                  <!--            </b-col>-->

                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Telephone"
                        label-for="h-telephone"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Telephone"
                          rules="tel|min_tel|max_tel"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="PhoneIcon"/>
                          </b-input-group-prepend>
                          <b-form-input
                              id="icons-phone"
                              v-model="form.phone_number"
                              placeholder="(+44)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Email -->

                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Email"
                        label-for="h-email"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|emailTest"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon"/>
                          </b-input-group-prepend>
                          <b-form-input
                              id="icons-mail"
                              v-model="form.email"
                              placeholder="example@.com"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--            Alias-->
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Alias"
                        label-for="h-Alias"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Alias"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                              id="icons-Alias"
                              v-model="form.test1"
                              placeholder="Enter Alias"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--            Gender-->

                  <b-col cols="6" md="6">
                    <b-form-group
                        label="Gender"
                        label-for="h-Gender"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Gender"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <v-select
                              class="w-100"
                              v-model="form.test2"
                              :options="[{title:'Male', value:'Male'},
                                         {title:'Female', value:'Female'},
                                         {title:'Non-binary', value:'Non-binary'},
                                         {title:'Prefer not to say', value:'Prefer not to say'}
                                         ]"
                              label="title"
                              placeholder="Select Gender"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--            Preferred Pronouns-->

                  <b-col cols="6" md="6">
                    <b-form-group
                        label="Preferred Pronouns"
                        label-for="h-Preferred_pronouns"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Preferred Pronouns"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <v-select
                              class="w-100"
                              v-model="form.test3"
                              :options="[{title:'They/Them', value:'They/Them'},
                                         {title:'He/Him', value:'He/Him'},
                                         {title:'She/Her', value:'She/Her'},
                                         {title:'Prefer not to say', value:'Prefer not to say'}
                                         ]"
                              label="title"
                              placeholder="Select Preferred Pronouns"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!--            NI Number-->

                  <b-col cols="6" md="6">
                    <b-form-group
                        label="NI Number"
                        label-for="h-Ni_number"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="NI Number"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <b-form-input
                              id="icons-Ni_number"
                              v-model="form.test4"
                              placeholder="Enter NI Number"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                  <b-col v-if="form.locum_job_role" cols="12" md="12">
                    <b-form-group
                        label="Locum Job Role"
                        label-for="locum_job_role"
                    >
                      <b-form-input
                          v-model="form.locum_job_role"
                          readonly
                      />
                    </b-form-group>
                  </b-col>


                  <!--       <b-col cols="12" md="12">-->
                  <!--        <b-form-group-->
                  <!--          label="Role"-->
                  <!--          label-for="h-role-name"-->
                  <!--        >-->
                  <!--        </b-form-group>-->
                  <!--      </b-col>-->
                  <!--          <b-col cols="12" md="12">-->
                  <!--           <b-form-group class="mb-1">-->
                  <!--                    <v-select-->
                  <!--                            label="title1"-->
                  <!--                            placeholder="Select Role"-->
                  <!--                            :options="[-->
                  <!--                            {title1:'Dietician', value:'hdhdh'},-->
                  <!--                            {title1:'Dietician 1', value:'hdhdh'},-->
                  <!--                            {title1:'Dietician 2', value:'hdhdh'},-->
                  <!--                            ]"-->
                  <!--                    />-->
                  <!--                </b-form-group>-->
                  <!--      </b-col>-->

                  <!--      <b-col cols="12" md="12">-->
                  <!--        <b-form-group-->
                  <!--          label="Telephone"-->
                  <!--          label-for="h-telephone"-->
                  <!--        >-->
                  <!--        </b-form-group>-->
                  <!--      </b-col>-->
                  <!--          <b-col cols="12" md="12">-->
                  <!--          <b-input-group class="input-group-merge mb-1">-->
                  <!--                <b-input-group-prepend is-text>-->
                  <!--                    <feather-icon icon="PhoneIcon" />-->
                  <!--                 </b-input-group-prepend>-->
                  <!--                 <b-form-input placeholder="Telephone" />-->
                  <!--           </b-input-group>-->
                  <!--      </b-col>-->


                  <!--        <b-col cols="12" md="12">-->
                  <!--        <b-form-group-->
                  <!--          label="Email"-->
                  <!--          label-for="h-email"-->
                  <!--        >-->
                  <!--        </b-form-group>-->
                  <!--      </b-col>-->
                  <!--          <b-col cols="12" md="12">-->
                  <!--           <b-input-group class="input-group-merge mb-1">-->
                  <!--      <b-input-group-prepend is-text>-->
                  <!--        <feather-icon icon="MailIcon" />-->
                  <!--      </b-input-group-prepend>-->
                  <!--      <b-form-input placeholder="Email" />-->
                  <!--    </b-input-group>-->
                  <!--      </b-col>-->


                  <!--        <b-col cols="12" md="12">-->
                  <!--        <b-form-group-->
                  <!--          label="NI Number"-->
                  <!--          label-for="h-ninumber"-->
                  <!--        >-->
                  <!--        </b-form-group>-->
                  <!--      </b-col>-->
                  <!--          <b-col cols="12" md="12">-->
                  <!--                <b-input-group class="input-group-merge mb-3">-->
                  <!--                 <b-input-group-prepend is-text>-->
                  <!--                      <feather-icon icon="PocketIcon" />-->
                  <!--                 </b-input-group-prepend>-->
                  <!--                   <b-form-input placeholder="NI Number" />-->
                  <!--                </b-input-group>-->
                  <!--      </b-col>-->

                  <b-col cols="12">
                    <b-row>
                      <div class="bank-details">
                        Home Address
                      </div>

                      <div class="optional-class">
                        Optional
                      </div>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Address"
                          label-for="home-address"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="pcn_address_length"
                        >
                          <b-form-input
                              id="home-address"
                              v-model="home_address.address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col v-if="home_address.address" md="6">
                    <div class="">
                      <b-form-group
                          label="City"
                          label-for="city"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="City"
                            rules="required|name_length"
                        >
                          <b-form-input
                              id="city"
                              v-model="home_address.city"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col v-if="home_address.address" md="6">
                    <div class="">
                      <b-form-group
                          label="Postal Code"
                          label-for="postal-code"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Postal Code"
                            rules="required|postal_code"
                        >
                          <b-form-input
                              id="postal-code"
                              v-model="home_address.postal_code"
                              placeholder="e.g.PR8 8UW"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col cols="12">
                    <b-row>
                      <div class="bank-details">
                        Bank Details
                      </div>

                      <div class="optional-class">
                        Optional
                      </div>
                    </b-row>
                  </b-col>

                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Bank Name"
                          label-for="v-bank-name"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Bank Name"
                            rules="pcn_name_length"
                        >
                          <b-form-input
                              id="v-bank-name"
                              v-model="bank_details.name"
                              placeholder="e.g.Bank of England"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col v-if="bank_details.name" md="6">
                    <div class="">
                      <b-form-group
                          label="Account Number"
                          label-for="account-number"
                      >

                        <validation-provider
                            #default="{ errors }"
                            name="Account Number"
                            rules="required|numeric|max_bank_account"
                        >
                          <b-form-input
                              id="account-number"
                              v-model="bank_details.account_number"
                              placeholder="xxxxxxxx"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col v-if="bank_details.name" md="6">
                    <b-form-group
                        label="Sort Code"
                        label-for="sort-code"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Sort Code"
                          rules="required|min_sort_code|max_sort_code"
                      >
                        <b-form-input
                            id="sort-code"
                            v-model="bank_details.sort_code"
                            placeholder="xx-xx-xx"

                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </b-col>

                  <!-- submit and reset -->
                  <b-col md="12">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mr-1"
                        type="submit"
                        variant="primary"
                        @click="profileUpdate()"
                    >
                      Save
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        @click="cancel()"
                    >
        <span class="text-secondary">
          Cancel
        </span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>

          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BRow,
  BFormFile,
  BOverlay
} from 'bootstrap-vue'
import profile from '@/apis/modules/profile'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import store from '../../../../store/profile/index'
import fileUploader from "@/constants/fileUploader";
import {imageBaseApiUrl} from '@/constants/config'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'profile',
  components: {
    store,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  mixins: [SuccessMessage, ErrorMessages],
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      isHaveProfileImage: false,
      userImage: null,
      changeImageButton: true,
      uploadImage: [],
      userData: {},
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        photo_url: '',
        role: '',
        locum_job_role: ''
      },
      bank_details: {
        name: '',
        account_number: '',
        sort_code: ''
      },
      home_address: {
        address: '',
        city: '',
        postal_code: ''
      }
    }
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true
    // }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    userData(val) {
      this.isHaveProfileImage = val.photo_url
      this.userImage = this.getUserImage()
      this.form.first_name = val.first_name
      this.form.last_name = val.last_name
      this.form.email = val.email
      this.form.phone_number = val.phone_number ? val.phone_number : ''
      this.form.photo_url = val.photo_url
      this.form.locum_job_role = val.locum ? val.locum.job_role.name : ''
      this.home_address.address = val.home_address && val.home_address[0] ? val.home_address[0].address : ''
      this.home_address.city = val.home_address && val.home_address[0] ? val.home_address[0].city : ''
      this.home_address.postal_code = val.home_address && val.home_address[0] ? val.home_address[0].postal_code : ''
      this.bank_details.name = val.bank_details.name
      this.bank_details.account_number = val.bank_details.account_number
      this.bank_details.sort_code = val.bank_details.sort_code
    }
  },
  methods: {
    changeImage() {
      this.changeImageButton = !this.changeImageButton
    },
    async removeImage() {
      this.$swal({
        title: 'Are you sure you want to remove profile image?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          await profile.profileRemove()
          await this.$store.dispatch('autoLogin')
          // this.$swal({
          //   icon: 'success',
          //   title: 'Published!',
          //   customClass: {
          //     confirmButton: 'btn btn-success'
          //   }
          // })
        }
      })
    },
    async profileUpdate() {
      if (await this.$refs.EditPersonalInfoForm.validate()) {
        try {
          const payload = {
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            email: this.form.email,
            phone_number: this.form.phone_number,
          }
          if (this.bank_details.name) {
            payload.bank_details = this.bank_details
          }
          if (this.home_address.address) {
            payload.home_address = this.home_address
          }
          await profile.profileUpdate(payload)
          this.showSuccessMessage('Profile updated')
          this.$emit('savePersonalInfo')
          await this.$store.dispatch('autoLogin')
        } catch (e) {
          this.convertAndNotifyError(e)
        }
      }
    },
    cancel() {
      this.$emit('savePersonalInfo')
    },
    async uploadFile() {
      try {
        fileUploader.store(this.uploadImage, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${imageBaseApiUrl}`,
        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
        })
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async loginUser() {
      try {
        this.loading = true
        this.userData = (await profile.getCurrentUser()).data.data
        this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async setImageKeyToApi(key) {
      (await profile.profileUpload({key: key, name: this.uploadImage.name}))
      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Photo updated successfully')
      this.$emit('savePersonalInfo')
      await this.$store.dispatch('autoLogin')
      // this.userImage = this.getUserImage(true)
      this.changeImageButton = true
      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },
  },
  mounted() {
    // console.log(this.userData.job_roles)
    this.loginUser()
  }
}
</script>

<style scoped>
.bank-details {
  /*position: absolute;*/
  /*height: 20.19px;*/
  margin-left: 5px;
  left: 2%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* Dark */
  color: #171822;
}

.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
  /*top: calc(50% - 21px/2 + 162px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: right;
  /* $dark-grey */
  color: #949494;
}
</style>
