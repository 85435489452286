<template>
  <div>

    <!-- User Details -->
    <b-card>
      <div class="row">
        <div class="col-md-2 col-lg-1 text-md-center mb-1 mb-md-0">
          <b-avatar
              variant="light-primary"
             :src="getUserImage(currentUser.user.id, true)"
              class=""
              size="75px"
              style="padding: 5px"
          />
        </div>
        <div class="col-md-10 col-lg-11">
          <div class="row">
            <div class="col-10">
              <h3
                  v-if="currentUser"
                  class="font-weight-700"
                  style="display: inline-block"
              >
                {{ currentUser.user.first_name}} {{ currentUser.user.last_name}}
              </h3>
              <b-badge
                  class="mx-1"
                  style="padding: 0.8rem"
                  variant="light-info"
              >
                <span
                    class="text-dark font-weight-700"
                    v-if="this.showLocumRegistered == true"
                >L</span
                >
              </b-badge>
              <b-badge
                  v-for="(role, index) in roles.slice(0, 5)"
                  :key="index"
                  class=" ml-1"
                  style="padding: 0.8rem"
                  variant="light-info"
              >
                <span class="text-dark font-weight-700"> {{ role }} </span>
              </b-badge>
            </div>
            <div class="col-2 text-right">
              <b-button
                  v-b-tooltip.hover.top="'Edit Profile'"
                  class="btn-icon bg-white shadow-sm font-weight-bold"
                  variant="white"
                  @click="isEditProfileSidebarActive = true"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20" />
              </b-button>
            </div>
          </div>
          <div class="row my-1">
            <div class="col">
              <div class="mb-1">
                Account created on
                {{
                  momentFormat(
                      currentUser.user.created_at,
                      "dddd DD - MM - YYYY"
                  )
                }}
              </div>

              <div class="mb-1">
                <b-tabs>
                  <!-- 01) Personal Info -->

                  <b-tab title="Personal Info">
                    <div class="alert-body">
                      <div class="d-flex flex-wrap">
                        <span class="mr-1">
                          <feather-icon icon="PhoneIcon" />
                          {{
                            currentUser.user.phone_number
                                ? currentUser.user.phone_number
                                : "N/A"
                          }}
                        </span>
                        <span class="mr-1">
                          <feather-icon icon="MailIcon" />
                          {{ currentUser.user.email }}
                        </span>

                        <span
                            class="mr-1"
                            v-if="currentUser.user.home_address[0]"
                        >
                          <feather-icon icon="MapPinIcon" />
                          {{
                            currentUser.user.home_address &&
                            currentUser.user.home_address[0]
                                ? currentUser.user.home_address[0].address
                                : ""
                          }},
                          {{
                            currentUser.user.home_address &&
                            currentUser.user.home_address[0]
                                ? currentUser.user.home_address[0].city
                                : ""
                          }},
                          {{
                            currentUser.user.home_address &&
                            currentUser.user.home_address[0]
                                ? currentUser.user.home_address[0].postal_code
                                : ""
                          }}
                        </span>
                      </div>
                    </div>
                  </b-tab>

                  <!--                 02) Professional Info -->

                  <b-tab title="Professional Info" title-item-class="ml-4 mr-4">
                    <div class="alert-body">
                      <!-- <h5 v-if="!currentUser.user.qalifications">
                        No professional information has been saved.
                      </h5> -->

                      <div class="d-flex flex-wrap justify-content-start" v-if="currentUser.user.qalifications">
                        <div class="mr-3">
                          <div>
                            <small class="font-weight-light"
                            >Qualification</small
                            >
                          </div>
                          <div>
                            <h5 class="font-weight-700">
                              {{
                                currentUser.user.qalifications.qalification
                                    ? currentUser.user.qalifications.qalification
                                        .name
                                    : "N/A"
                              }}
                            </h5>
                          </div>
                        </div>
                        <div class="mr-3">
                          <div>
                            <small class="font-weight-light">Speciality</small>
                          </div>
                          <div>
                            <h5 class="font-weight-700">
                              {{
                                currentUser.user.qalifications.speciality
                                    ? currentUser.user.qalifications.speciality
                                        .name
                                    : "N/A"
                              }}
                            </h5>
                          </div>
                        </div>
                        <div class="mr-3">
                          <div>
                            <small class="font-weight-light">IT System</small>
                          </div>
                          <div>
<!--                            <h5 class="font-weight-700">-->
<!--                              {{-->
<!--                                currentUser.user.qalifications.it_system-->
<!--                                    ? currentUser.user.qalifications.it_system-->
<!--                                        .name-->
<!--                                    : ""-->
<!--                              }}-->
<!--                            </h5>-->
                            <b-badge class="mr-1" variant="light-info" v-for="professionalInfoItSystem in professionalInfoItSystems">
                              <span class="text-dark ">{{professionalInfoItSystem.label}}</span>
                            </b-badge>
                            <h5 v-if="Object.keys(professionalInfoItSystems).length === 0"  class="mb-0 font-weight-700">N/A
                            </h5>
                          </div>
                        </div>
                        <div class="mr-3">
                          <div>
                            <small class="font-weight-light"
                            >Smart Card Number</small
                            >
                          </div>
                          <div>
                            <h5 class="font-weight-700">
                              {{
                                currentUser.user.qalifications.smart_card_number ? currentUser.user.qalifications.smart_card_number : "N/A"
                              }}
                            </h5>
                          </div>
                        </div>
                        <div class="mr-3">
                          <div>
                            <small class="font-weight-light">Skills</small>
                          </div>
                          <div v-if="currentUser.user.qalifications && currentUser.user.qalifications.skill">
                            <b-badge
                                class="mr-1"
                                variant="light-info"
                                :key="skill"
                                v-for="skill in currentUser.user.qalifications
                                .skills"
                            >
                              <span class="text-primary">{{ skill }}</span>
                            </b-badge>
                            <h5 v-if="currentUser.user.qalifications.skills.length === 0"  class="mb-0 font-weight-700">N/A
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
              <div>
                <button
                    class="btn btn-primary"
                    @click="isEditSidebarActive = true"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <!--     Switch to-->

    <div class="row">
      <div class="col-12 col-sm-4 col-md-6 col-lg-7">
        <h3 class="font-weight-700" v-if="!(pcns.length == 0 && practices.length == 0)"> Switch to </h3>
      </div>
      <div class="col-12 col-sm-8 col-md-6 col-lg-5">

        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mb-1 float-right"
            size="sm"
            variant="primary"
            @click="createPractice()"
        >
          Create New Practice
        </b-button>

        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mb-1 float-right mr-1"
            size="sm"
            variant="primary"
            @click="createPCN()"
        >
          Create New PCN
        </b-button>
      </div>
    </div>

    <!--     Switch to-->


    <div>
      <b-overlay
          :show="tableLoading"
          rounded="sm"
      >
        <b-row>
          <!-- PCN -->
          <b-col v-for="pcn in pcns" :key="pcn.id" cols="12" md="4">
            <a v-if=""

               :href="`${pcnAppUrl}/scheduler?auth_pcn_id=${pcn.pcn.id}`"
               target="_blank" >
              <b-card class="">
                <div class="d-md-flex flex-row  text-md-left text-center ">
                  <img alt="" class="img-fluid" src="@/assets/images/pages/iconaa.png">

                  <div class="ml-1 mt-1 font-weight-bolder profile-text">
                    <div class="d-flex align-items-center ">

                      <div>
                        <h4 class="mb-0 font-weight-700 ">{{ pcn.pcn.name }} </h4>

                      </div>

                    </div>
                    <b-row>
                      <b-col cols="12">
                        <label class="font-small-3 font-weight-light">{{ pcn.pcn.type.name }}</label>

                      </b-col>
                      <b-col cols="12">
                        <!--                    <div class="" v-for="role in practice.team.roles">-->

                        <!--                      <b-badge class="ml-1" style="padding:8px" variant="light-info"  v-for="role in practice.team.roles">-->
                        <!--                        <span class="text-dark font-weight-700"> {{role.name}} </span>-->
                        <!--                      </b-badge>-->

                        <!--&lt;!&ndash;                      <b-badge class="" style="padding:2px" variant="info">&ndash;&gt;-->
                        <!--&lt;!&ndash;                        <span class="text-white font-weight-700"> {{role.name}} </span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                      </b-badge>&ndash;&gt;-->
                        <!--                    </div>-->
                        <b-badge  class="mr-1" style="padding:8px"
                                 variant="light-info">
                          <span class="text-dark font-weight-700"> PCN Admin</span>
                        </b-badge>


                      </b-col>
                      <b-col cols="12">
<!--                        <label class="font-small-3 font-weight-700">385 connected staff's</label>-->
<!--                        <label class="font-small-3 font-weight-700 mr-1 ml-1">|</label>-->
<!--                        <label class="font-small-3 font-weight-700">{{ pcn.practices_count }} connected PCN's</label>-->
                      </b-col>

                      <b-col>

                      </b-col>

                    </b-row>
                  </div>
                </div>
              </b-card>
            </a>
          </b-col>

          <b-col v-for=" practice in practices" :key="practice.id" cols="12" md="4">
            <a v-if=""

               :href="practice.team.roles.some(el =>el.name ==='practice-admin')?`${mpAppUrl}/dashboard?practice_id=${practice.id}`:`${mpAppUrl}/my-shifts?practice_id=${practice.id}`"
               target="_blank" >
              <!--              <a v-if=""-->
              <!--                 :href=" practice.team.roles.some(el =>el.name ==='practice-admin')?`https://www.mp-v2.hyreapp.co.uk/scheduler?practice_id=${practice.id}`:`https://www.mp-v2.hyreapp.co.uk/my-shifts?practice_id=${practice.id}`"-->
              <!--                 target="_blank">-->

              <!--                          <a v-if=""-->
              <!--                             :href=" practice.team.roles.some(el =>el.name ==='practice-admin')?`https://www.hyre-mp.locumboxapp.com/scheduler?practice_id=${practice.id}`:`https://www.hyre-mp.locumboxapp.com/my-shifts?practice_id=${practice.id}`"-->
              <!--                             target="_blank">-->


              <!--            :href="`https://www.hyre-mp.locumboxapp.com/scheduler?practice_id=${practice.id}`"-->
              <!--            :href="`http://mp.hyre-be-v2.test:8081/scheduler?practice_id=${practice.id}`"-->
              <!--            http://mp.hyre-be-v2.test:8081-->
              <!--            :href="`${config.mpAppUrl}/scheduler?practice_id=${practice.id}`"-->

              <b-card class="">
                <div class="d-md-flex flex-row  text-md-left text-center ">
                  <img alt="" class="img-fluid" src="@/assets/images/pages/iconac.png">

                  <div class="ml-1 mt-1 font-weight-bolder profile-text">
                    <div class="d-flex align-items-center ">

                      <div>
                        <h4 class="mb-0 font-weight-700 ">{{ practice.name.slice(0, 25) + (practice.name.length > 25 ? "..." : "")}} </h4>

                      </div>

                    </div>
                    <b-row>
                      <b-col cols="12">
                        <label class="font-small-3 font-weight-light">{{ practice.type.name }}</label>


                      </b-col>
                      <b-col cols="12">
                        <!--                    <div class="" v-for="role in practice.team.roles">-->

                        <!--                      <b-badge class="ml-1" style="padding:8px" variant="light-info"  v-for="role in practice.team.roles">-->
                        <!--                        <span class="text-dark font-weight-700"> {{role.name}} </span>-->
                        <!--                      </b-badge>-->

                        <!--&lt;!&ndash;                      <b-badge class="" style="padding:2px" variant="info">&ndash;&gt;-->
                        <!--&lt;!&ndash;                        <span class="text-white font-weight-700"> {{role.name}} </span>&ndash;&gt;-->
                        <!--&lt;!&ndash;                      </b-badge>&ndash;&gt;-->
                        <!--                    </div>-->
                        <b-badge v-for="role in practice.team.roles" :key="role.id" class="mr-1" style="padding:8px"
                                 variant="light-info">
                          <span class="text-dark font-weight-700"> {{ role.name }} </span>
                        </b-badge>


                      </b-col>
                      <!--                  <b-col cols="12">-->
                      <!--                    <label class="font-small-3 font-weight-light" v-if="practice.pivot.is_admin">Admin</label>-->
                      <!--                    <label class="font-small-3 font-weight-light" v-if="practice.pivot.is_salaried_staff">Salaried Staff</label>-->
                      <!--                  </b-col>-->

                      <b-col
                          v-if="!(practice.team.roles.filter(role => role.name ==='salaried-staff').length !==0 && practice.team.roles.length === 1)"
                          cols="12">
                        <!--                        <span class="font-weight-700"> 3 </span>-->
                        <!--                        <span class="mr-1"> connected practices</span>-->
                        <!--                        <span class="font-weight-700">1</span>-->
                        <!--                        <span>connected PCNs</span>-->

                      </b-col>
                      <!--                  <b-col cols="12">-->
                      <!--                    <b-button-->
                      <!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                      <!--                        size="sm"-->
                      <!--                        variant="primary"-->
                      <!--                        class="mr-1 mt-1"-->
                      <!--                    >-->
                      <!--                     View-->
                      <!--                    </b-button>-->
                      <!--                    <b-button-->
                      <!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                      <!--                        size="sm"-->
                      <!--                        variant="primary"-->
                      <!--                        class="mr-1 mt-1"-->
                      <!--                        @click="editPractice(practice.id)"-->
                      <!--                    >-->
                      <!--                    Edit-->
                      <!--                    </b-button>-->
                      <!--                    <b-button-->
                      <!--                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                      <!--                      size="sm"-->
                      <!--                      variant="primary"-->
                      <!--                      class="mr-1 mt-1"-->
                      <!--                  >-->
                      <!--                    Go To Dashboard-->
                      <!--                  </b-button>-->
                      <!--                  </b-col>-->
                    </b-row>
                  </div>
                </div>
              </b-card>
            </a>
          </b-col>

        </b-row>
      </b-overlay>


    </div>
    <div class="ml-1">
      <h3   v-if="!showLocumRegistered" class="font-weight-700 ">I want to register as a locum</h3>
      <h3 v-else class="font-weight-700 ">Locum Dashboard</h3>
      <b-button
          v-if="!showLocumRegistered"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class=" mb-3"
          size="sm"
          variant="primary"
          @click="locumRegister()"

      >
        Register locum
      </b-button>

      <a
          v-else
          :href="locumAppUrl"
          target="_blank">

        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class=" mb-3"
            size="sm"
            variant="primary"


        >
          Go to Locum Dashboard
        </b-button>
      </a>
    </div>
    <b-modal
        id="modal-center"
        v-model="locumregisterModel"
        centered
        hide-footer
        size="lg"
        title="Locum Register"
    >
      <b-row class="mx-1">
        <b-col cols="12" md="12">
          <validation-observer ref="CreatePracticeForm">
            <div class="mb-1">
              <b-form-file
                  ref="file_cv"
                  v-model="files.cv"
                  class="mb-1"
                  drop-placeholder="Drop file here..."
                  no-drop
                  placeholder="CV"
              />
              <b-form-file
                  ref="file_cct"
                  v-model="files.cct"
                  class="mb-1"
                  drop-placeholder="Drop file here..."
                  no-drop
                  placeholder="CCT"
              />
              <b-form-file
                  ref="file_passport"
                  v-model="files.passport"
                  class="mb-1"
                  drop-placeholder="Drop file here..."
                  no-drop
                  placeholder="Passport"
              />
              <b-form-file
                  ref="file_bds"
                  v-model="files.dbs"
                  drop-placeholder="Drop file here..."
                  no-drop
                  placeholder="DBS"
              />

            </div>
            <b-form-group class="mb-2">
              <label>Job Role</label>
              <validation-provider
                  #default="{ errors }"
                  name="Job role"
                  rules="required"
              >
                <v-select
                    transition=""
                    v-model="job_role_id"
                    :options="jobRoleOption"
                    label="name"
                    placeholder="Select Job role"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-1"
                type="submit"
                variant="primary"
                @click="locumRegisterFormSubmit()"
            >
              Submit
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="resetData()"
            >
        <span class="text-secondary">
          Reset
        </span>
            </b-button>

            <!--            </b-form>-->

          </validation-observer>

        </b-col>

      </b-row>

    </b-modal>


    <edit-practice ref="editPractice"/>
    <create-practice ref="createPractice"
                     @practiceCreated="practiceCreated()"/>
    <create-pcn ref="createPcn" @pcnCreated="pcnCreated()"/>

    <locum-register ref="locumRegister"/>

    <EditSideBar
        :is-edit-sidebar-active.sync="isEditSidebarActive"
        @toggleSideBars="toggleSideBarsa()"
    />
    <EditProfileSideBar
        :is-edit-profile-sidebar-active.sync="isEditProfileSidebarActive"
        @toggleSideBars="toggleSideBars()"
    />
  </div>
</template>

<script>
import {locumAppUrl} from '@/constants/config'
import Available from '@/views/dashboard/component/availability-table'
import EditSideBar from '@/views/dashboard/component/reset-password'
import EditProfileSideBar from '@/views/dashboard/component/edit-profile/edit-profile-sidebar'
import Practice from '@/apis/modules/Practice'
import PCNApi from "@/apis/modules/PCN";
import Profile from "@/apis/modules/profile";
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import EditPractice from '@/views/dashboard/component/edit-practice'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions'
import Ripple from 'vue-ripple-directive'
import CreatePractice from '@/views/dashboard/component/create-practice'
import CreatePcn from '@/views/dashboard/component/create-pcn.vue'
// import config from "@/constants/config";
import { mapActions, mapGetters } from 'vuex'
import LocumRegister from '@/views/dashboard/locum/locum-register'
import {mpAppUrl,pcnAppUrl} from '@/constants/config'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import FileUploadMixin from '@/mixins/FileUploadMixin'
export default {
  name: 'profile-image-bar',
  components: {
    LocumRegister,
    CreatePractice,
    CreatePcn,
    EditPractice,
    Available,
    EditProfileSideBar,
    EditSideBar,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BOverlay,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    BCardBody,
    BCardHeader,
    BMedia,
    BTable,
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormFile,
    VBTooltip,
    extend,
    ValidationObserver,
    ValidationProvider
  },
  directives: {
    BCardActions,
    Ripple,
    'b-tooltip': VBTooltip
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, FileUploadMixin],
  data () {
    return {
      locumAppUrl : null,
      job_role_id: [],
      jobRoleOption: [],
      locumregisterModel: false,
      mpAppUrl,
      pcnAppUrl,
      showLocumRegistered: false,
      isEditSidebarActive: false,
      isEditProfileSidebarActive: false,
      user: '',
      fullName: '',
      practices: '',
      pcns: [],
      tableLoading: false,
      files: {
        cv: [],
        cct: [],
        passport: [],
        dbs: []
      },
      roles: [],
      professionalInfoItSystems:{}
    }
  },
  watch:{
    currentUser(){
      this.professionalInfoItSystems = this.currentUser.user.multiple_it_systems.map((x) => ({
        // value: x.it_system[0].id,
        label: x.it_system[0].name,
      }))
    }
  },
  methods: {
    async uploadToS3 (type, file) {
      await this.uploadFile(type, file)
    },
    async practiceCreated () {
      await this.getPracticeAndNetwork()
    },
    async pcnCreated () {
      await this.getPcnAndNetwork()
    },
    async editPractice () {
      await this.$refs.editPractice.showModel(true)
    },
    async locumRegister () {
      this.locumregisterModel = true
      await this.jobRoleList()
    },
    async locumRegisterFormSubmit () {
      // await this.$refs.locumRegister.showModel(true)
      if( await this.$refs.CreatePracticeForm.validate()){
        this.$swal({
          title: `Are you sure you want to register locum ${this.job_role_id.name}?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, register',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
          if (result.value) {
            const payload = {
              job_role_id: this.job_role_id.id
            }
            await Practice.registerLocum(payload)
            //upload files after registration since files need to  be store with locum table id
            if (this.$refs.file_cv.files[0]) await this.uploadFile('cv', this.$refs.file_cv.files[0])
            if (this.$refs.file_cct.files[0]) await this.uploadFile('cct', this.$refs.file_cct.files[0])
            if (this.$refs.file_passport.files[0]) await this.uploadFile('passport', this.$refs.file_passport.files[0])
            if (this.$refs.file_bds.files[0]) await this.uploadFile('bds', this.$refs.file_bds.files[0])
            this.showSuccessMessage('Locum Registered Successfully')
            await this.isUserLocum()
            this.locumregisterModel = false
          }
        })
      }
    },
    resetData() {
      this.files.cv = '',
          this.files.cct = '',
          this.files.passport = '',
          this.files.dbs = '',
          this.job_role_id = ''
    },
    async isUserLocum () {
      try {
        this.showLocumRegistered = (await Practice.checkUserIsLocum()).data.data.success
        // console.log(this.showLocumRegistered)
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async createPractice () {
      await this.$refs.createPractice.showModel(true)
    },
    async createPCN () {
      await this.$refs.createPcn.showModel(true)
    },
    ...mapActions(['setCurrentUser']),
    toggleSideBars () {
      this.isEditProfileSidebarActive = !this.isEditProfileSidebarActive
      this.getUserImage()
      this.setCurrentUser(Profile.getCurrentUser().data.data)
    },
    toggleSideBarsa () {
      this.isEditSidebarActive = !this.isEditSidebarActive
    },
    async getPracticeAndNetwork () {
      try {
        this.tableLoading = true
        const response = await Practice.getPractices()
        this.practices = response.data.data
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async getPcnAndNetwork () {
      try {
        this.tableLoading = true
        this.pcns = (await PCNApi.getPcnList()).data.data
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async jobRoleList () {
      try {
        const practiceListResponse = await Practice.getJobRoleList()
        this.jobRoleOption = practiceListResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    checkInvitations(){
      try{
        var responds = JSON.parse(localStorage.getItem('redirectOBj'));
        if(responds){
          if(this.currentUser.user.email === responds.redirectEmail && responds.redirectUrl === 'invitation'){
            window.location = '/invitations'
          }else if(this.currentUser.user.email === responds.redirectEmail && responds.redirectUrl === 'setPassword'){
            this.isEditSidebarActive = true
          }
          localStorage.removeItem('redirectOBj')
        }
      }catch(e){

      }
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted () {
    this.locumAppUrl = locumAppUrl
    this.getPracticeAndNetwork()
    this.getPcnAndNetwork()
    this.isUserLocum()
    this.roles = this.currentUser.user.job_roles.map((x) => x.name)
    this.roles = _.values(_.uniq(_.map(this.roles)))
    this.fullName = this.currentUser.user.first_name.concat(' '+this.currentUser.user.last_name)
    this.professionalInfoItSystems = this.currentUser.user.multiple_it_systems.map((x) => ({
      // value: x.it_system[0].id,
      label: x.it_system[0].name,

    }))
    this.checkInvitations()
    // console.log(this.fullName)
  }
}
</script>


<style scoped>
</style>
