<template>
  <div>
    <b-card no-body>
      <b-overlay
          v-if="employmentLength >= 1"
          :show="loading"
          rounded="sm"
      >
        <b-row  class="">


          <!-- first name -->

          <b-col class="mt-1" cols="12" xl="12" v-for="employmentDetail in employmentDetails">
            <h4>{{employmentDetail.practice.name}}</h4>
            <validation-observer ref="EditPersonalInfoForm">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Date hired"
                        label-for="Date hired"
                        class="mt-1"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Date hired"
                          rules=""
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                              v-model="employmentDetail.date_hired"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d'}"
                              class="form-control"
                              placeholder=""
                              disabled

                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Employment type -->
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Employment type"
                        label-for="h-Preferred_pronouns"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Employment type"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <v-select
                              class="w-100"
                              v-model="employmentDetail.employment_type"
                              :options="[{title:'Full time', value:'Full time'},
                                         {title:'Part time', value:'Part time'},
                                         {title:'Casual', value:'Casual'},
                                         {title:'AARS', value:'AARS'}
                                         ]"
                              disabled
                              label="title"
                              placeholder=""
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
<!--                  Salary-->
                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          label="Salary"
                          label-for="home-Salary"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Salary"
                            rules=""
                        >
                          <b-form-input
                              id="home-address"
                              v-model="employmentDetail.salary"
                              placeholder=""
                              type="number"
                              disabled

                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="6" md="6">
                    <b-form-group
                        label="DBS status"
                        label-for="h-DBS status"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="DBS status"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <v-select
                              class="w-100"
                              v-model="employmentDetail.dbs_status"
                              :options="[{title:'Not started', value:'Not started'},
                                         {title:'In progress,', value:'In progress'},
                                         {title:'Completed', value:'Completed'},
                                         {title:'Prefer not to say', value:'Prefer not to say'}
                                         ]"
                              label="title"
                              placeholder=""
                              disabled

                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" md="6">
                    <b-form-group
                        label="DBS renewal Date "
                        label-for="h-DBS renewal Date"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="DBS renewal Date"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                              v-model="employmentDetail.dbs_renewal_date"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d'}"
                              class="form-control"
                              placeholder=""
                              disabled
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                  <!-- submit and reset -->
                  <b-col md="12">
<!--                    <b-button-->
<!--                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
<!--                        class="mr-1"-->
<!--                        type="submit"-->
<!--                        variant="primary"-->
<!--                        @click="cancel()"-->
<!--                    >-->
<!--                      &lt;!&ndash;                      @click="profileUpdate()"&ndash;&gt;-->
<!--                      Save-->
<!--                    </b-button>-->
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>

          </b-col>
        </b-row>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="cancel()"
            class="mt-2"
        >
        <span class="text-secondary">
          Cancel
        </span>
        </b-button>
      </b-overlay>
      <div v-if="employmentLength == 0">
        <div>
      <b-col cols="12" class="text-center">
        <div class="empty-state py-5">
          <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z" fill="#F0F8FC"/>
            <path d="M66.1895 87.4937H92.1618M66.1895 100.484H83.5058" stroke="#1B9AAA" stroke-width="3" stroke-linecap="round"/>
            <path d="M81.3234 40.7456H54.3247C50.8315 40.7456 48 43.5763 48 47.0666V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0303" stroke="#171822" stroke-width="3" stroke-linecap="round"/>
            <path d="M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.471 62.0764 99.5463 62.0764C92.622 62.0764 87.0081 56.4629 87.0081 49.5382C87.0081 42.6135 92.622 37 99.5463 37C101.268 37 102.91 37.3472 104.403 37.9755" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
            <path d="M109.825 42.3568C109.157 41.4007 108.357 40.5423 107.453 39.8071" stroke="#171822" stroke-width="2.5" stroke-linecap="round"/>
            <path d="M98.7554 43.0996V50.1572C98.7554 50.2105 98.7983 50.2536 98.8525 50.2536H104.855" stroke="#1B9AAA" stroke-width="2.5" stroke-linecap="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.872 55.3193C140.222 55.3193 142.127 57.2249 142.127 59.5747C142.127 61.9244 140.222 63.83 137.872 63.83C135.522 63.83 133.616 61.9244 133.616 59.5747C133.616 57.2249 135.522 55.3193 137.872 55.3193Z" fill="#DCEEF8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M149.788 41.7021C153.078 41.7021 155.745 44.3694 155.745 47.6596C155.745 50.9498 153.078 53.617 149.788 53.617C146.498 53.617 143.831 50.9498 143.831 47.6596C143.831 44.3694 146.498 41.7021 149.788 41.7021Z" fill="#DCEEF8"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z" fill="#DCEEF8"/>
          </svg>

          <p class="txt-grey">No Employment Info</p>
        </div>
      </b-col>
    </div>
      </div>
      <div v-else>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BRow,
  BFormFile,
  BOverlay
} from 'bootstrap-vue'
import profile from '@/apis/modules/profile'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import store from '../../../../store/profile/index'
import fileUploader from "@/constants/fileUploader";
import {imageBaseApiUrl} from '@/constants/config'
import {mapActions, mapGetters} from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'


export default {
  name: 'profile',
  components: {
    flatPickr,
    store,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar
  },
  mixins: [SuccessMessage, ErrorMessages],
  directives: {
    Ripple
  },
  data() {
    return {
      employmentLength: -1, 
      loading: false,
      isHaveProfileImage: false,
      userImage: null,
      changeImageButton: true,
      uploadImage: [],
      userData: {},
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        photo_url: '',
        role: '',
        locum_job_role: ''
      },
      employmentDetails:[]
    }
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true
    // }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    // userData(val) {
    //   this.isHaveProfileImage = val.photo_url
    //   this.userImage = this.getUserImage()
    //   this.form.first_name = val.first_name
    //   this.form.last_name = val.last_name
    //   this.form.email = val.email
    //   this.form.phone_number = val.phone_number ? val.phone_number : ''
    //   this.form.photo_url = val.photo_url
    //   this.form.locum_job_role = val.locum ? val.locum.job_role.name : ''
    //   this.home_address.address = val.home_address && val.home_address[0] ? val.home_address[0].address : ''
    //   this.home_address.city = val.home_address && val.home_address[0] ? val.home_address[0].city : ''
    //   this.home_address.postal_code = val.home_address && val.home_address[0] ? val.home_address[0].postal_code : ''
    //   this.bank_details.name = val.bank_details.name
    //   this.bank_details.account_number = val.bank_details.account_number
    //   this.bank_details.sort_code = val.bank_details.sort_code
    // }
  },
  methods: {

    // async profileUpdate() {
    //   if (await this.$refs.EditPersonalInfoForm.validate()) {
    //     try {
    //       const payload = {
    //         first_name: this.form.first_name,
    //         last_name: this.form.last_name,
    //         email: this.form.email,
    //         phone_number: this.form.phone_number,
    //       }
    //       if (this.bank_details.name) {
    //         payload.bank_details = this.bank_details
    //       }
    //       if (this.home_address.address) {
    //         payload.home_address = this.home_address
    //       }
    //       await profile.profileUpdate(payload)
    //       this.showSuccessMessage('Profile updated')
    //       this.$emit('savePersonalInfo')
    //       await this.$store.dispatch('autoLogin')
    //     } catch (e) {
    //       this.convertAndNotifyError(e)
    //     }
    //   }
    // },
    cancel() {
      this.$emit('close')
    },
    async loginUser() {
      try {
        this.loading = true
        this.userData = (await profile.getCurrentUser()).data.data
        this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async employmentInfo() {
      const response = await profile.getEmploymentInformation()
      this.employmentDetails = response.data,
      this.employmentLength =  response.data.length
    },

  },
  mounted() {
    // console.log(this.userData.job_roles)
    this.loginUser()
    this.employmentInfo()
  }
}
</script>

<style scoped>

.bank-details {
  /*position: absolute;*/
  /*height: 20.19px;*/
  margin-left: 5px;
  left: 2%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* Dark */
  color: #171822;
}

.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
  /*top: calc(50% - 21px/2 + 162px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: right;
  /* $dark-grey */
  color: #949494;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
