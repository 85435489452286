<template>
  <div>
    <b-modal
        id="modal-center"
        centered
        title="Update Practice"
        hide-footer
        size="lg"
        v-model="show"
    >
      <b-row class="mx-1">
        <b-col cols="12" md="12">
          <validation-observer ref="resetPasswordForm">
            <b-form @submit.prevent>
              <b-form-group class="">
                <label>Name</label>
                <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label>Practice Type</label>
                <validation-provider
                    #default="{ errors }"
                    name="Practice type"
                    rules="required"
                >
                <v-select
                    v-model="practice_type"
                    label="name"
                    :options="option"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="">
                <label>Address</label>
                <validation-provider
                    #default="{ errors }"
                    name="address"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="address"
                        v-model="form.address"
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="">
                <label>City</label>
                <validation-provider
                    #default="{ errors }"
                    name="city"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="city"
                        v-model="form.city"
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="">
                <label>Postal Code</label>
                <validation-provider
                    #default="{ errors }"
                    name="postal_code"
                    rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="post_code"
                        v-model="form.postal_code"
                        type="text"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="updatePractice()"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="resetData()"
              >
        <span class="text-secondary">
          Reset
        </span>
              </b-button>
              <!--              </b-col>-->


            </b-form>

          </validation-observer>

        </b-col>


      </b-row>

    </b-modal>

  </div>
</template>

<script>
import { BModal,  VBModal,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions";
import profile from "@/apis/modules/profile";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import SuccessMessage from "@/mixins/SuccessMessage";
import ErrorMessages from "@/mixins/ErrorMessages";

export default {
name: "edit-practice",
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,


    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessages],

  data(){
  return{
    passwordFieldType:'',
    show:false,
    form: {
      name: '',
      address: '',
      city: '',
      postal_code: '',
    },
    practice_type:'',
    option:[
        {
      name:'Test 1',
      value:'1'
      },
      {
        name:'Test 2',
        value:'2'
      },
      {
        name:'Test 3',
        value:'3'
      }
    ]
  }
  },
  computed: {

  },
  methods:{
  showModel(data){
    this.show = data
  },

    resetData() {
      this.form = {
        name: '',
        address: '',
        city: '',
        postal_code: '',
      }
      this.practice_type=''

    },
    async updatePractice() {
      if (await this.$refs.resetPasswordForm.validate()) {
        try {
          this.form.type = this.practice_type.value
          // await profile.resetPassword(this.form)
          // console.log(this.form)
          this.showSuccessMessage('Practice Update Successful')

        } catch (e) {
          this.convertAndNotifyError(e)
        }
      }
    }

  }
}

</script>

<style scoped>

</style>
