<template>
  <b-row>
    <b-overlay
        :show="addLoader"
        rounded="sm"
    >
      <b-col cols="12">

        <b-table
            :current-page="currentPage"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="items"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            hover
            responsive
        >
          <!-- Name $ Avatar -->

          <template #cell(monday)="data">
            <b-avatar :variant="data.item.mocolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.monday }}
          </template>

          <template #cell(tuesday)="data">
            <b-avatar :variant="data.item.tucolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.tuesday }}

          </template>

          <template #cell(wednesday)="data">
            <b-avatar :variant="data.item.wecolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.wednesday }}

          </template>

          <template #cell(thursday)="data">
            <b-avatar :variant="data.item.thcolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.thursday }}

          </template>

          <template #cell(friday)="data">
            <b-avatar :variant="data.item.frcolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.friday }}

          </template>

          <template #cell(saturday)="data">
            <b-avatar :variant="data.item.sacolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.saturday }}

          </template>

          <template #cell(sunday)="data">
            <b-avatar :variant="data.item.sucolor" rounded size="10">
              <feather-icon icon="CheckIcon" size="1"/>
            </b-avatar>
            {{ data.item.sunday }}

          </template>

          <!-- Paid Unpaid Button -->


        </b-table>
      </b-col>
    </b-overlay>
  </b-row>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem
  },
  directives: {
    Ripple
  },
  data () {
    return {
      addLoader: false,
      unavailabilities: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      fields: [
        {
          key: 'monday',
          label: 'Monday'
        },
        {
          key: 'tuesday',
          label: 'Tuesday'
        },
        {
          key: 'wednesday',
          label: 'wednesday'
        },
        {
          key: 'thursday',
          label: 'thursday'
        },
        {
          key: 'friday',
          label: 'friday'
        },
        {
          key: 'saturday',
          label: 'saturday'
        },
        {
          key: 'sunday',
          label: 'sunday'
        }


      ],
      items: [

        {
          'monday': '09-00 - 12.00',
          'mocolor': 'success',
          'tuesday': '09-00 - 12.00',
          'tucolor': 'success',
          'wednesday': '09-00 - 12.00',
          'wecolor': 'success',
          'thursday': '09-00 - 12.00',
          'thcolor': 'success',
          'friday': '09-00 - 12.00',
          'frcolor': 'success',
          'saturday': 'Unavailable',
          'sacolor': 'secondary',
          'sunday': '09-00 - 12.00',
          'sucolor': 'success'
        },

        {
          'monday': '14:00 - 18:00',
          'mocolor': 'success',
          'tuesday': '16:00 - 20:00',
          'tucolor': 'success',
          'wecolor': '',
          'thcolor': '',
          'frcolor': '',
          'sacolor': '',
          'sucolor': ''
        }
      ]
    }
  },




}
</script>


<style scoped>

</style>
