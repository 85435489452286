<template>
  <div>
    <b-modal
        id="modal-center"
        centered
        title="Create Practice"
        hide-footer
        size="lg"
        v-model="show"
        no-close-on-backdrop

    >
      <b-row class="mx-1">
        <b-col cols="12" md="12">
          <validation-observer
              ref="CreatePracticeForm"
          >
            <b-form
                @submit.prevent>

              <b-row class="">
                <b-col cols="12" md="6">

                  <b-form-group class="">
                    <label>Name</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required|name_length"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="name"
                            v-model="form.name"
                            type="text"
                            placeholder="Enter name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>Practice Email (Optional)</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="emailTest"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="email"
                            v-model="form.email"
                            type="text"
                            placeholder="Enter practice email"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="">
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>Phone Number (Optional)</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="tel|min_tel|max_tel"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="phone_number"
                            placeholder="Enter phone number"
                            v-model="form.phone_number"
                            type="text"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>Practice code (Optional)</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Practice Code"
                        rules="practice_code"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="practice_code"
                            v-model="form.practice_code"
                            type="text"
                            placeholder="Enter practice code"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="">
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>Service charge (£/hr) (Optional)</label>
                    <validation-provider
                        #default="{ errors }"
                        name="service Charge"
                        rules="numeric|max"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="service_charge"
                            v-model="form.service_charge"
                            type="text"
                            placeholder="Enter service change"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label>Practice Type</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Practice Type"
                        rules="required"
                    >
                      <v-select
                          placeholder="Select Practice"
                          v-model="practice_type"
                          label="name"
                          :options="option"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group class="">
                <label>Address 1</label>
                <validation-provider
                    #default="{ errors }"
                    name="Address 1"
                    rules="required|pcn_address_length"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="address"
                        v-model="form.address"
                        type="text"
                        placeholder="Enter address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="">
                <label>Address 2</label>
                <validation-provider
                    #default="{ errors }"
                    name="Address 2"
                    rules="required|pcn_address_length"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="address"
                        v-model="form.address_2"
                        type="text"
                        placeholder="Enter address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row class="">
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>City</label>
                    <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required|name_length"

                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="city"
                            v-model="form.city"
                            type="text"
                            placeholder="Enter city"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>Postal Code</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Postal Code"
                        rules="required|postal_code"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-input
                            id="post_code"
                            v-model="form.postal_code"
                            type="text"
                            placeholder="e.g.PR8 8UW"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group class="">
                    <label>Your Staff Job Role</label>
                    <validation-provider
                        #default="{ errors }"
                        name="Job Role"
                        rules="required"
                    >
                      <v-select
                          v-model="selectedRole"
                          :options="job_role_list"
                          label="text"
                          placeholder="Select Role"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>


             <div class="float-right mb-lg-5">
               <b-button
                   v-if="!buttonDisable"
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   class="mr-1"
                   type="submit"
                   variant="primary"
                   @click="updatePractice()"
               >
                 Submit
               </b-button>
               <b-button
                   v-else
                   v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                   class="mr-1"
                   type="submit"
                   variant="primary"
                   disabled

               >
                 Submit
               </b-button>
               <b-button
                   v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                   type="reset"
                   variant="outline-secondary"
                   @click="resetData()"
               >
        <span class="text-secondary">
          Reset
        </span>
               </b-button>

             </div>

            </b-form>

          </validation-observer>

        </b-col>


      </b-row>

    </b-modal>

  </div>
</template>

<script>
import { BModal,  VBModal,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import SuccessMessage from "@/mixins/SuccessMessage";
import ErrorMessages from "@/mixins/ErrorMessages";
import Practice from "@/apis/modules/Practice";
import {required} from '@validations'

export default {
  name: "create-practice",
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,


    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessages],

  data(){
    return{
      required,
      buttonDisable:false,
      passwordFieldType:'',
      show:false,
      form: {
        name: '',
        address: '',
        address_2:'',
        city: '',
        postal_code: '',
      },
      practice_type:'',
      option:[],
      job_role_list:[],
      selectedRole:''

    }
  },
  computed: {

  },
  async mounted() {
    await this.practiceTypeList()
    await this.getJobRoleList()
  },
  methods:{
    showModel(data){
      this.show = data
    },

    resetData() {
      this.form = {
        name: '',
        address: '',
        city: '',
        postal_code: '',
      }
      this.practice_type=''
      this.buttonDisable = false

    },
    async updatePractice() {
      if (await this.$refs.CreatePracticeForm.validate()) {
        try {
          this.form.type_id = this.practice_type.id
          this.buttonDisable = true
          this.form.job_role_id = this.selectedRole.id
          await Practice.createPractice(this.form)
          this.showSuccessMessage('Practice Created Successfully')
          this.$emit('practiceCreated')
          this.show = false
          this.resetData()
        } catch (e) {
          this.convertAndNotifyError(e)
          this.buttonDisable = false
        }
      }


    },
    async getJobRoleList () {
      try {
        this.loading = true
        const Response = await Practice.getJobRoleList()

        this.job_role_list = Response.data.data.map((x) => ({
              id: x.id,
              text: x.name,
            })
        )



      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }

    },

    async practiceTypeList (){
      try {

        const practiceListResponse =  await Practice.getPracticeList()
        this.option = practiceListResponse.data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    }

  }
}

</script>

<style scoped>
</style>





















