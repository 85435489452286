<template>
  <div>
    <b-modal
        id="modal-center"
        centered
        title="Create PCN"
        hide-footer
        size="lg"
        v-model="show"
    >

      <b-row class="mx-1">
        <b-col cols="12" md="12">
          <validation-observer
              ref="CreatePcnForm"
          >
            <b-form
                @submit.prevent>


               <div class=" text-center img-fluid">
                  <img alt="" class="" src="@/assets/images/pages/iconaa.png">
                  <h4 class="mb-0 font-weight-light">Administrator</h4>
               </div>

              <b-form-group class="">
                <label>Organisation name</label>
                <validation-provider
                    #default="{ errors }"
                    name="Organisation name"
                    rules="required|pcn_name_length"

                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text"
                        placeholder="Enter name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label>Organisation type</label>
                <validation-provider
                    #default="{ errors }"
                    name="Organisation type"
                    rules="required"
                >
                  <v-select
                      placeholder="Select"
                      v-model="pcn_type"
                      label="name"
                      :options="options"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="">
                <label>Address 1</label>
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required|address|pcn_address_length"

                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="address"
                        v-model="form.address"
                        type="text"
                        placeholder="Enter address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="">
                <label>Address 2</label>
                <validation-provider
                    #default="{ errors }"
                    name="Address"
                    rules="required|address|pcn_address_length"

                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="address"
                        v-model="form.address_2"
                        type="text"
                        placeholder="Enter address"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="">
                <label>City</label>
                <validation-provider
                    #default="{ errors }"
                    name="City"
                    rules="required|name_length"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="city"
                        v-model="form.city"
                        type="text"
                        placeholder="Enter city"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="">
                <label>Postal Code</label>
                <validation-provider
                    #default="{ errors }"
                    name="Postal code"
                    rules="required|postal_code"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                        id="post_code"
                        v-model="form.postal_code"
                        type="text"
                        placeholder="e.g.PR8 8UW"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                  v-if="!buttonDisable"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  @click="updatePcn()"
              >
                Submit
              </b-button>
              <b-button
                  v-else
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mr-1"
                  type="submit"
                  variant="primary"
                  disabled

              >
                Submit
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="resetData()"
              >
        <span class="text-secondary">
          Reset
        </span>
              </b-button>


            </b-form>

          </validation-observer>

        </b-col>


      </b-row>

    </b-modal>

  </div>
</template>

<script>
import { BModal,  VBModal,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions";
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import SuccessMessage from "@/mixins/SuccessMessage";
import ErrorMessages from "@/mixins/ErrorMessages";
import PCNApi from "@/apis/modules/PCN";


export default {
  // name: "create-pcn",
  components: {
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,


    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessages],

  data(){
    return{
      buttonDisable:false,
      passwordFieldType:'',
      show:false,
      form: {
        name: '',
        address: '',
        address_2:'',
        city: '',
        postal_code: '',
      },
      pcn_type:'',
      options:[],

    }
  },
  computed: {

  },
  async mounted() {

  },
  methods:{
    async showModel(data){
      this.show = data
      await this.pcnTypeList()
    },

    resetData() {
      this.form = {
        name: '',
        address: '',
        city: '',
        postal_code: '',
      }
      this.pcn_type=''
      this.buttonDisable = false

    },
    async updatePcn() {


      if (await this.$refs.CreatePcnForm.validate()) {
        try {
          this.form.type_id = this.pcn_type.id
          this.buttonDisable = true
          await PCNApi.createPcn(this.form)
          this.showSuccessMessage('PCN Created Successfully')
          this.$emit('pcnCreated')
          this.show = false
          this.resetData()
        } catch (e) {
          this.convertAndNotifyError(e)
          this.buttonDisable = false
        }
      }
    },
    async pcnTypeList (){
      try {

       this.options =  (await PCNApi.getPcnTypes()).data.data

      } catch (e) {
        this.convertAndNotifyError(e)
      }
    }

  }
}

</script>

<style scoped>
</style>





















